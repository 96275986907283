(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('AssignmentsService', AssignmentsService);
    
    AssignmentsService.$inject = ['$http', '$q'];

    function AssignmentsService($http, $q) {

        var requestUrl = 'api/assignment';

        var service = {
            getList: getList,
            getAssignmentForEditing: getAssignmentForEditing,
            getAvailableDocuKinds: getAvailableDocuKinds,
            saveOrUpdate: saveOrUpdate,
            getSubmitForm: getSubmitForm,
            submitAssignment: submitAssignment,
            submitAssignmentCorrection: submitAssignmentCorrection,
            submitAssignmentPaidCorrection: submitAssignmentPaidCorrection,
            submitMultipleAssignments: submitMultipleAssignments,
            changeAssignmentStatus: changeAssignmentStatus,
            checkUnique: checkUnique,
            findAssignment: findAssignment,
            joinAssignment: joinAssignment,
        };
        return service;
    
        function getList(pageableFilter) {
            return $http.post(requestUrl + '/list', pageableFilter).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function findAssignment(obj) {
            return $http.post(requestUrl + '/find-assignment', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function joinAssignment(obj) {
            return $http.post(requestUrl + '/self-enrollment', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function getAssignmentForEditing(id) {
            return $http.get(requestUrl + '/for-editing/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function changeAssignmentStatus(id, status) {
            return $http.get(requestUrl + '/change-status/' + id + '/' + status).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function checkUnique(id, shortcode) {
            return $http.get(requestUrl + '/check-unique/' + id + '/' + shortcode).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getSubmitForm(documentId) {
            return $http.get(requestUrl + '/submit-form/' + documentId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
        
        function submitAssignment(obj) {
            return $http.post(requestUrl + '/submit', obj).then(
              function (response) {
                  return response.data.data;
              },
              function (errResponse) {
                  return $q.reject(errResponse);
              });
        }
    
        function submitAssignmentCorrection(obj) {
            return $http.post(requestUrl + '/submit-correction', obj).then(
              function (response) {
                  return response.data.data;
              },
              function (errResponse) {
                  return $q.reject(errResponse);
              });
        }
    
        function submitAssignmentPaidCorrection(obj) {
            return $http.post(requestUrl + '/submit-correction-paid', obj).then(
              function (response) {
                  return response.data.data;
              },
              function (errResponse) {
                  return $q.reject(errResponse);
              });
        }
    
        function submitMultipleAssignments(obj, id) {
            return $http.post(requestUrl + '/submit-multiple/' + id, obj).then(
              function (response) {
                  return response.data.data;
              },
              function (errResponse) {
                  return $q.reject(errResponse);
              });
        }
    
        function getAvailableDocuKinds() {
            return $http.get(requestUrl + '/available-docu-kinds').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function saveOrUpdate(obj) {
            return $http.post(requestUrl + '/save-or-update', obj).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('AssignmentsAddController', AssignmentsAddController);
    
    AssignmentsAddController.$inject = ['AssignmentsService', 'ModalService',
        '$localStorage', '$state', '$stateParams', 'UserService', 'Utils', 'AccountService'];

    function AssignmentsAddController(AssignmentsService, ModalService,
        $localStorage, $state, $stateParams, UserService, Utils, AccountService) {
        var vm = this;
        vm.pending = false;
        vm.activeTab = 'assignments';
        
        vm.obj = {};
        vm.obj.emailAddresses = [];
        vm.docuKinds = [];
        vm.emailAddressesInvalid = null;
        vm.emailAddressesNotRegistered = null;
        vm.debounceDelay = 2000;
        vm.saveOrUpdate = saveOrUpdate;
        vm.transformEmailAddress = transformEmailAddress;
        vm.generate = generate;
        vm.validateShortcode = validateShortcode;
        vm.aiDetectionEnabled = AccountService.account.contractAiDetectionEnabled;
        vm.clientSettingGrammarCheckEnabled = AccountService.account.clientSettings.grammarCheckEnabled;

        this.$onInit = function () {
            vm.pending = true;
            AssignmentsService.getAvailableDocuKinds().then(function (ret) {
                vm.docuKinds = ret.data;
    
                if ($stateParams.id) {
                    AssignmentsService.getAssignmentForEditing($stateParams.id).then(function (obj) {
                        vm.obj = obj.data;
                        if (vm.obj.startDate) {
                            vm.obj.startDate = new Date(vm.obj.startDate);
                            // vm.obj.startTime = new Date(vm.obj.startDate);
                        }
                        if (vm.obj.endDate) {
                            vm.obj.endDate = new Date(vm.obj.endDate);
                            vm.obj.endTime = new Date(vm.obj.endDate);
                        }
                        vm.pending = false;
                    });
                } else {
                    vm.obj.startDate = new Date();
                    // vm.obj.startTime = new Date();
                    // vm.obj.startTime.setHours(0, 0, 0, 0);
                    vm.obj.endTime = new Date();
                    vm.obj.endTime.setHours(23, 59, 0, 0);
                    vm.obj.emailAddresses = [];
                    vm.pending = false;
                }
            });
        };

        function setTimeInDates() {
            // if (vm.obj.startDate && vm.obj.startTime) {
            //     vm.obj.startDate.setHours(vm.obj.startTime.getHours(), vm.obj.startTime.getMinutes())
            // }
            if (vm.obj.endDate && vm.obj.endTime) {
                vm.obj.endDate.setHours(vm.obj.endTime.getHours(), vm.obj.endTime.getMinutes())
            }
        }
    
        function generate() {
            vm.obj.shortcode = getRandomChars(6);
            checkUnique($stateParams.id, vm.obj.shortcode);
        }
        
        function validateShortcode() {
            checkUnique($stateParams.id, vm.obj.shortcode);
        }
        
        function checkUnique(id, shortcode) {
            if (!id) {
                id = null;
            }
            AssignmentsService
                .checkUnique(id, shortcode)
                .then(function (resp) {
                    if (!resp.data) {
                        ModalService.showErrorModal({data: {code: 126}});
                    }
                    vm.pending = false;
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                    vm.pending = false;
                });
        }

        function getRandomChars(length) {
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
    
        function saveOrUpdate(form) {
            if (!Utils.validateForm(form)) {
                return;
            }
            vm.pending = true;
            setTimeInDates();
            AssignmentsService
                .saveOrUpdate(vm.obj)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    $state.go('assignments');
                    $stateParams.id = null;
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                    vm.pending = false;
                });
        }
        
        function transformEmailAddress(chips) {
            if (vm.obj.emailAddresses) {
                vm.obj.emailAddresses = vm.obj.emailAddresses.slice(0); // workaround na bug w md-chips
            }
            
            vm.emailAddressesInvalidError = false;
            vm.emailAddressesInvalid = null;

            var chipsArray = chips.split(/[\n\s,]+/);
            UserService.getAvailableStudentEmails(chipsArray).then(
              function (result) {
                  var registeredEmails = result.data;
                  angular.forEach(chipsArray, function(chip) {
                      if (validateEmailAddress(chip, registeredEmails)) {
                          if (vm.obj.emailAddresses.indexOf(chip) < 0) {
                              vm.obj.emailAddresses.push(chip);
                          }
                      }
                  });
              }
            )

            return null;
        }
    
        function validateEmailAddress(email, registeredEmails) {
            var validationRegexp = /\S+@\S+\.\S+/;
            if (!validationRegexp.test(email)) {
                vm.emailAddressesInvalid = (vm.emailAddressesInvalid ? (vm.emailAddressesInvalid + ', ') : '') + email;
                return null;
            }
            if (registeredEmails.indexOf(email) < 0) {
                vm.emailAddressesNotRegistered = (vm.emailAddressesNotRegistered ? (vm.emailAddressesNotRegistered + ', ') : '') + email;
                return null;
            }
            return true;
        }

    }
})();

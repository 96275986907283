(function () {
    'use strict';

    function DocumentProtocolController($translate, DocumentProtocolService) {
        var vm = this;
        vm.getDocumentReport = getDocumentReport;
        vm.getTranslationForClientProtocolTypeCode = getTranslationForClientProtocolTypeCode;
        vm.getProtocolForDocument = getProtocolForDocument;

        function getDocumentReport(clientProtocolType) {
            var fileName = $translate.instant(getTranslationForClientProtocolTypeCode(clientProtocolType.code)) + '_' + (vm.document.docuAuthor ? '_' + vm.document.docuAuthor : '');
            DocumentProtocolService.generateThesisOpinion(
                vm.document.documentId,
                clientProtocolType.id,
                fileName
            );
        }

        function getProtocolForDocument(protocolForDocument) {
            var fileName = protocolForDocument.fileName + "_" + vm.document.docuTitle;
            DocumentProtocolService.generateProtocolForDocuments(
                vm.document.documentId,
                protocolForDocument.id,
                fileName
            );
        }

        /**
         * transform's ClientProtocolType Code to translation key
         * for example 'CLIENT_PROTOCOL_DECISION_SGGW' return 'document.protocol.decision.sggw'
         */
        function getTranslationForClientProtocolTypeCode(code) {
           return $translate.instant(
               code
                .replace('CLIENT_PROTOCOL_', 'document.protocol.')
                .replace('_', '.')
                .toLowerCase());
        }
    }

    angular.module('documentProtocol', [])
    .component('documentProtocol', {
        templateUrl: 'app/account/documents/documents-list/document-protocol/document-protocol.template.html',
        controller: DocumentProtocolController,
        bindings: {
            document: '<',
            clientProtocolTypes: '<',
            protocolForDocuments: '<'
        }
    });

})();

(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('DocumentController', DocumentController);

    DocumentController.$inject = [
        '$scope', '$q', '$rootScope', '$localStorage', 'queryRequest', 'searchRequest', 'tableSettingsRequest', '$interval',
        'AccountService', '$timeout', '$state', '$stateParams', '$translate', '$mdToast', 'DocumentService', '$mdDialog', 'TokenService', '$mdBottomSheet',
        '$log', 'InstanceService', 'DetectInstanceService', 'DOCUSTATUSES', 'OPDOCUSTATUSES', 'DocumentReportXlsService', 'ContractService',
        '$cookies', 'PlagiatValidationService', 'ScrollService', 'OrganisationalUnitsService', 'Utils', 'TableSelectOptions',
        'JSA_ORDER_STATUS', 'ProtocolForDocumentService', 'JsaService', 'ModalService', 'Principal', 'ORPPD_STATUS', 'DOCUMENT_STATUS', 'PERMISSIONS',
        'ClientSettingService', 'AssignmentsService'
    ];

    function DocumentController(
        $scope, $q, $rootScope, $localStorage, queryRequest, searchRequest, tableSettingsRequest, $interval,
        AccountService, $timeout, $state, $stateParams, $translate, $mdToast, DocumentService, $mdDialog, TokenService, $mdBottomSheet,
        $log, InstanceService, DetectInstanceService, DOCUSTATUSES, OPDOCUSTATUSES, DocumentReportXlsService, ContractService,
        $cookies, PlagiatValidationService, ScrollService, OrganisationalUnitsService, Utils, TableSelectOptions,
        JSA_ORDER_STATUS, ProtocolForDocumentService, JsaService, ModalService, Principal, ORPPD_STATUS, DOCUMENT_STATUS, PERMISSIONS,
        ClientSettingService, AssignmentsService
    ) {
        var vm = this;

        vm.jsaOrderStatus = JSA_ORDER_STATUS;

        vm.instance = InstanceService.instance().lang;
        vm.conversionDomain = DetectInstanceService.getConversionAllowance();
        vm.theme = $cookies.get('plagiat-theme');
        vm.timezone = InstanceService.instance().timezoneDocuments;
        moment.locale(vm.instance);
        vm.convertToDate = convertToDate;

        vm.jsa = AccountService.getClientSettings().jsaIntegration;
        vm.assignmentsEnabled = AccountService.getClientSettings().assignmentsEnabled;
        vm.documentImportEnabled = AccountService.getClientSettings().documentImportEnabled && !AccountService.role.isStudent();
        vm.selfEnrollmentEnabled = AccountService.getClientSettings().assignmentsEnabled && AccountService.role.isStudent();

        vm.key = $stateParams.key;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.success = null;
        vm.isLoading = false;
        vm.uploadIsActive = false;
        vm.uploadFile = uploadFile;
        vm.uploadText = uploadText;
        vm.uploadTextFlag = false;
        vm.uploadObj = {};
        vm.getTheFiles = getTheFiles;
        vm.submitAssignment = submitAssignment;
        vm.submitAssignmentCorrection = submitAssignmentCorrection;
        vm.submitAssignmentPaidCorrection = submitAssignmentPaidCorrection;

        vm.searchData = {};

        vm.formdata = new FormData();

        vm.getDocuments = getDocuments;
        vm.getDocumentsSimpleSearch = documentsSimpleSearch;
        vm.reloadDocuments = reloadDocuments;

        vm.files = null;
        vm.availableExtension = '';
        vm.getAvailableExtensions = getAvailableExtensions;

        vm.queryRequest = new queryRequest();
        vm.queryRequest.limit = $localStorage.documentsListLimit ? $localStorage.documentsListLimit : vm.queryRequest.limit;
        vm.searchRequest = new searchRequest();

        vm.startPaging = startPaging;
        vm.goTo = goTo;
        vm.changeLimit = changeLimit;

        vm.options = new tableSettingsRequest();

        vm.showDetailsBlock = false;
        vm.pickedDocument = false;
        vm.details = {};
        vm.getDetails = getDetails;
        vm.getDocumentStatusTooltipKey = getDocumentStatusTooltipKey;
        vm.statuses = [];
        vm.documentsToRefresh = [];
        vm.documentsToRefreshPromise;

        vm.getUploadFormConfiguration = getUploadFormConfiguration;
        vm.getUploadFormOffCheckingConfiguration = getUploadFormOffCheckingConfiguration;

        vm.generateReportXls = generateReportXls;
        vm.clientSettings = AccountService.getClientSettings();

        vm.report2020Disabled = vm.clientSettings.report2020Disabled;

        vm.isJsaDisabled = true;

        vm.tableSelectOptions = new TableSelectOptions();
        vm.selected = [];
        vm.deSelectRow = deSelectRow;
        vm.selectRow = selectRow;
        vm.toggleSelection = toggleSelection;
        vm.resetSelection = resetSelection;
        vm.closeBottomSheet = closeBottomSheet;
        vm.removeDocuments = removeDocuments;
        vm.rejectDocuments = rejectDocuments;
        vm.addDocuments = addDocuments;
        vm.showWarningOnFactor1 = showWarningOnFactor1;
        vm.showWarningOnFactor2 = showWarningOnFactor2;
        vm.canAcceptJsaReport = canAcceptJsaReport;
        vm.shouldShowDocumentFormTabs = shouldShowDocumentFormTabs;
        vm.shouldShowOnlyUniversalDocumentForm = shouldShowOnlyUniversalDocumentForm;
        vm.shouldShowDocumentAssignmentForm = shouldShowDocumentAssignmentForm;
        vm.assignmentAfterDueDate = assignmentAfterDueDate;
        vm.isUploadFileVisible = isUploadFileVisible;
        vm.selfEnrollment = selfEnrollment;
        vm.ORPPD_STATUS = ORPPD_STATUS;
        vm.documentStatus = DOCUMENT_STATUS;

        checkJsaAccessData();
        vm.clientProtocolTypes = [];
        if (vm.clientSettings.protocolsEnabled) {
            findAllProtocolForDocuments();
            vm.clientProtocolTypes = vm.clientSettings.clientProtocolTypes;
        }

        vm.clientTypePublishingHouse = AccountService.clientType.isPublishingHouse();
        vm.clientTypeSchool = AccountService.clientType.isSchool();

        vm.isCoordinatorVisible = isCoordinatorVisible;
        vm.isSuperAdmin = AccountService.role.isSuperAdmin();
        vm.isReseller = AccountService.role.isReseller();
        vm.isMinistry = AccountService.role.isMinistry();
        vm.isUploadTextVisible = false;
        vm.multipleSimilarityFactorsAreDisplayed = !vm.clientSettings.hideDocuSimilarityFactor && !vm.clientSettings.hideDocuSimilarityFactorExt && vm.clientSettings.showReversedSimilarityFactor;
        vm.showReversedSimilarityFactor = vm.clientSettings.showReversedSimilarityFactor;
    
        vm.authorsSimplifiedFields = vm.clientSettings.authorsSimplifiedFields;

        this.$onInit = function () {
            if (AccountService.clientType.isPap()) {
                $state.go('reports');
            }
            vm.isUploadTextVisible = isUploadTextVisible();
            vm.clientHasPermToEditDocument = vm.clientSettings.clientConfigurationAllowUserEditDocument;
        };

        $scope.$on('$destroy', function() {
            cancelDocumentsToRefreshInterval();
        });
        
        $scope.$on('on-get-documents', function () {
            reloadDocuments();
        });

        function reloadDocuments() {
            vm.documentsSearchRequest.simpleSearch.length > 0 ? documentsSimpleSearch(vm.documentsSearchRequest) : getDocuments(vm.documentsSearchRequest);
        }

        function checkJsaAccessData() {
            if (vm.jsa) {
                JsaService.getAccessData().then(function (response) {
                    if (response.tokenLastSaveDate !== null && response.polonCode !== null) {
                        vm.isJsaDisabled = false;
                    }
                })
            }
        }

        function resetSelection() {
            if(vm.tableSelectOptions.selectedAll) {
                vm.selected.length = 0;
                vm.tableSelectOptions = new TableSelectOptions();
                $mdBottomSheet.hide();
            }
        }

        function canAcceptJsaReport(document){
            return document.jsaOrderStatus === JSA_ORDER_STATUS.COMPLETE;
        }

        function shouldShowDocumentFormTabs(){
            return vm.uploadIsActive && vm.jsa && !vm.assignmentSubmitForm;
        }

        function shouldShowOnlyUniversalDocumentForm(){
            return vm.uploadIsActive && !vm.jsa && !vm.assignmentSubmitForm;
        }

        function shouldShowDocumentAssignmentForm(){
            return vm.uploadIsActive && vm.assignmentSubmitForm;
        }

        function showWarningOnFactor1(val, clientWarningThreshold1) {
            if (vm.clientSettings.warningThresholds) {
                if (clientWarningThreshold1) {
                    return 100 * val > clientWarningThreshold1;
                } else {
                    return 100 * val > vm.clientSettings.warningThreshold1;
                }
            }
            return false;
        }
        
        function showWarningOnFactor2(val, clientWarningThreshold2) {
            if (vm.clientSettings.warningThresholds) {
                if (clientWarningThreshold2) {
                    return 100 * val > clientWarningThreshold2;
                } else {
                    return 100 * val > vm.clientSettings.warningThreshold2;
                }
            }
            return false;
        }
        
        function closeBottomSheet() {
            vm.selected.length = 0;
            vm.tableSelectOptions = new TableSelectOptions();
            $mdBottomSheet.hide();
        }

        function openBottomSheet() {
            $mdBottomSheet.show({
              templateUrl: 'app/account/documents/bottom-sheet.template.html',
              controllerAs: 'bootomSheet',
              parent: angular.element(document.getElementById('documents')),
              controller: function GridBottomSheetCtrl(){
                var bootomSheet = this;
                bootomSheet.checked = true;
                bootomSheet.closeBottomSheet = vm.closeBottomSheet;
                bootomSheet.selected = vm.selected;
                bootomSheet.tableSelectOptions = vm.tableSelectOptions;
                bootomSheet.total = vm.totalDocuments;
                bootomSheet.toggleSelection = vm.toggleSelection;
                bootomSheet.closeBottomSheet = vm.closeBottomSheet;

                bootomSheet.isIndividual = vm.isIndividual;
                bootomSheet.isStudent = vm.isStudent;
                bootomSheet.isAdmin = vm.isAdmin;
                bootomSheet.isMinistry = vm.isMinistry;
                bootomSheet.generateReportXls = vm.generateReportXls;
                bootomSheet.removeDocuments = vm.removeDocuments;
                bootomSheet.rejectDocuments = vm.rejectDocuments;
                bootomSheet.addDocuments = vm.addDocuments;
                bootomSheet.userDatabaseAddingEnabled = userDatabaseAddingEnabled();
            },
            isLockedOpen: true,
            disableParentScroll: false,
            disableBackdrop: true
          });
        }

        function selectRow(pickedId) {
            if( vm.selected.length === 1) {
                openBottomSheet();
            }
            angular.forEach(vm.documents, function (value) {
                if (value.documentId === pickedId) {
                    if (vm.tableSelectOptions.selectedHelper.indexOf(pickedId) === -1) {
                        vm.tableSelectOptions.selectedHelper.push(value);
                    }
                }
            });
        }

        function selectRows(obj) {
            angular.forEach(obj, function (value) {
                if (vm.selected.indexOf(value.documentId) === -1) {
                    vm.selected.push(value.documentId);
                }
            })
        }

        function toggleSelection(total) {
            if(!vm.tableSelectOptions.selectedAll) {
                selectRows(vm.documents);
            }
            vm.tableSelectOptions.selectedAll = !vm.tableSelectOptions.selectedAll;
            vm.tableSelectOptions.selectedNo = total;
            if( !vm.tableSelectOptions.selectedAll ) {
                closeBottomSheet();
            }
        }

        function deSelectRow(pickedId) {
            if( vm.selected.length === 0) {
                closeBottomSheet();
            }
            vm.tableSelectOptions.selectedAll = false;
            angular.forEach(vm.documents, function (value) {
                if (value.documentId === pickedId) {
                    vm.tableSelectOptions.selectedHelper.splice(vm.tableSelectOptions.selectedHelper.indexOf(value), 1);
                }
            });
        }

        if(vm.clientSettings && vm.clientSettings.clieForeignAlphabetAlertLimit == 0){
        	vm.clientSettings.clieForeignAlphabetAlertLimit = 1;
        }

        $scope.$on('uploadActive', function () {
            vm.uploadIsActive = true;
            getUploadFormConfiguration(DocumentService.uploadedData).then(function(response){
                vm.uploadObj = response;
                vm.uploadObj.documentSkipAddresses = [];
            });
        });

        function shouldShowSynchronizationValidationWindow() {
            if (vm.jsa) {
                JsaService.getAccessData().then(function (response) {
                    if (response.tokenLastSaveDate === null || response.polonCode === null) {
                        ModalService.showDialogModal("document.messages.jsa.synchronization.error");
                    } else {
                        vm.isJsaDisabled = false;
                    }
                })
            }
        }

        function setRoles(){
            vm.isIndividual = AccountService.role.isIndividual();
            vm.isOperator = AccountService.role.isOperator();
            vm.isStudent = AccountService.role.isStudent();
            vm.isLecturer = AccountService.role.isLecturer();
            vm.isAdmin = AccountService.role.isAdmin();
            vm.isSuperAdmin = AccountService.role.isSuperAdmin();
        }

        function verifyContract(){

        	vm.uploadObj.addWithoutCheckingError = false;
        	vm.uploadObj.addWithoutCheckingDisabled = false;
        	vm.uploadObj.addWithoutChecking = false;

            ContractService.getInfo().then(function(response){
                if (AccountService.clientType.isCodeAccount()) {
                    // Code Accounts are not depending on contract only. Code clients can check document using purchased codes.
                    return;
                }
            	if(response.data.contractType === 0 && !response.data.isContractActive ){
                    setErrorWithoutCheckingText('global.messages.error.docu-count-exceeded');
                } else if(response.data.contractType === 1 && response.data.contNumOfPlagReportsLeft < vm.uploadObj.size){
                	 setErrorWithoutCheckingText('global.messages.error.docu-character-count-exceeded');
                } else if(response.data.userQueryCounter <= 0 && response.data.userQueryCounterType === 0){
                	setErrorWithoutCheckingText('global.messages.error.user-query-limit-exceeded');
                } else if(response.data.userQueryCounter < vm.uploadObj.size && response.data.userQueryCounterType === 1 ){
                	setErrorWithoutCheckingText('global.messages.error.user-query-limit-exceeded');
                } else if(response.data.contMaxDocPlagSize < vm.uploadObj.size){
                	setErrorWithoutCheckingText('global.messages.error.docu-size-exceeded');
                }
            });
        }

        function setErrorWithoutCheckingText(translationKey){
        	vm.uploadObj.addWithoutCheckingError = true;
        	vm.uploadObj.addWithoutCheckingDisabled = true;
        	if (vm.isStudent && AccountService.clientType.isSchool()) { // ITDPANEL17-1836 dla uczniów nie pokazujemy guzika <Dodaj bez sprawdzania>
                vm.uploadObj.addWithoutChecking = false;
            } else {
                vm.uploadObj.addWithoutChecking = true;
            }
        	vm.uploadObj.addWithoutCheckingErrorText = translationKey;
        }

        function getUploadFormOffCheckingConfiguration(obj){
            var deferred = $q.defer();
            if(!vm.isIndividual){
                DocumentService.operator.getConfigurationOffChecking().then(function(response){
                    vm.uploadObj = Object.assign( response.data, obj);
                });
            }
            else {
                vm.uploadObj = obj;
            }
            deferred.resolve(vm.uploadObj);
            return deferred.promise;
        }

        function getUploadFormConfiguration(obj){
            var deferred = $q.defer();
            if (!vm.isIndividual){
                DocumentService.operator.getConfiguration().then(function(response){
                    vm.uploadObj = Object.assign( response.data, obj);
                    populateAiDetection();
                    verifyContract();
                    populateAuthor();
                });
            } else {
                vm.uploadObj = obj;
                vm.uploadObj.documentSkipAddresses = [];
            }
            deferred.resolve(vm.uploadObj);
            return deferred.promise;
        }

        function afterUploadLandingPage() {
            if (vm.key === 'error') {
                var confirm = $mdDialog.confirm()
                .title('')
                .clickOutsideToClose(true)
                .htmlContent($translate.instant("global.messages.error.error"))
                .ariaLabel('')
                .ok('Ok')
                .openFrom('#left');
                $mdDialog.show(confirm).then(function (result) {

                });
            }
            if (vm.key === 'success') {
                var confirm = $mdDialog.confirm()
                .title('')
                .clickOutsideToClose(true)
                .htmlContent($translate.instant("global.messages.success.afterpayment"))
                .ariaLabel('')
                .ok('Ok')
                .openFrom('#left');
                $mdDialog.show(confirm).then(function (result) {

                });
            }
        }

        function prepareSearchRequest(){
            if(!vm.isIndividual){
                DocumentService.operator.getConfiguration().then(function(response){
                    vm.searchData.docuKindInfo = response.data.docuKindInfo.selectItems;
                    OrganisationalUnitsService.getAll().then(function(response){
                        vm.searchData.orgUnitInfo = response.data;
                    });
                    vm.searchData.documentParameterDefinitions = response.data.documentParameterDefinitions;
                });
            }
        }

        function prepareDocuStatuses(){
            vm.searchData.statuses = vm.isIndividual ? DOCUSTATUSES : OPDOCUSTATUSES;
            if (vm.jsa || vm.isSuperAdmin) {
                vm.searchData.statuses.push({'id': -9, 'status': 'jsa-error' });
            }
            if (vm.isSuperAdmin || vm.assignmentsEnabled) {
                vm.searchData.statuses.push({'id': -11, 'status': 'draft' });
            }
        }

        function prepareMassActions() {
            vm.deferred = $q.defer();
            vm.tableSelectOptions.ids.length = 0;
            vm.tableSelectOptions.selectedNo = vm.tableSelectOptions.selectedAll ? vm.tableSelectOptions.selectedNo : vm.selected.length;
            angular.forEach(vm.tableSelectOptions.selectedHelper, function (value) {
                if( value.docuStatus === 1 ){
                    vm.tableSelectOptions.ids.push(value.documentId);
                }
            });
            vm.deferred.resolve(vm.tableSelectOptions);
            return vm.deferred.promise;
        }

        function removeDocuments() {
            prepareMassActions().then(function(response){
                var confirm = $mdDialog.confirm()
                .title($translate.instant("document.confirmremovemany"))
                .htmlContent($translate.instant("document.marked-docs") + ' ' + response.selectedNo)
                .ariaLabel($translate.instant("document.areyousure"))
                .ok($translate.instant("global.button.remove"))
                .cancel($translate.instant("global.button.cancel"))
                .openFrom('#left');
                $mdDialog.show(confirm).then(function () {
                    vm.isLoaderActive = true;
                    DocumentService.removeMassDocuments({ids: vm.selected}, vm.searchRequest, response.selectedAll).then(function () {
                        vm.isLoaderActive = false;
                        TokenService.broadcastTokens();
                        closeBottomSheet();
                        reloadDocuments();
                    }).catch(function (response) {
                        PlagiatValidationService.getModalError(response.data);
                    });
                });
            });
        }

        function rejectDocuments() {
            prepareMassActions().then(function(response) {
                vm.rejectIndexFromDbText = response.selectedAll ? $translate.instant("document.marked-docs") + ' ' + response.selectedNo +'<br /><br />'+ $translate.instant("document.messages.index.reject.mass") : $translate.instant("document.marked-docs") + vm.selected.length +'<br />'+ $translate.instant("document.rejectfromdatabaseno") + response.ids.length + '<br /><br />' + $translate.instant("document.cantrejectothers");
                var confirm = $mdDialog.confirm()
                .title($translate.instant("global.confirm.action"))
                .htmlContent(vm.rejectIndexFromDbText)
                .ariaLabel($translate.instant("document.areyousure"))
                .ok($translate.instant("global.button.reject"))
                .cancel($translate.instant("global.button.cancel"))
                .openFrom('#left');
                $mdDialog.show(confirm).then(function () {
                    if( vm.tableSelectOptions.ids.length > 0 || response.selectedAll ){
                        vm.isLoaderActive = true;
                        DocumentService.indexRejectFromDatabase({ids:response.ids}, vm.searchRequest, response.selectedAll).then(function () {
                            vm.isLoaderActive = false;
                            closeBottomSheet();
                            reloadDocuments();
                        }, function (error) {
                         PlagiatValidationService.getModalError(error.data);
                     });
                    }
                });
            });
        }

        function addDocuments() {
            prepareMassActions().then(function(response) {
                vm.addToIndexText = response.selectedAll ? $translate.instant("document.marked-docs") + ' ' + response.selectedNo +'<br /><br />'+ $translate.instant("document.messages.index.add.mass") : $translate.instant("document.marked-docs") + vm.selected.length +'<br />'+ $translate.instant("document.addtodatabaseno") + response.ids.length + '<br /><br />' + $translate.instant("document.cantaddothers");
                var confirm = $mdDialog.confirm()
                .title($translate.instant("global.confirm.action"))
                .htmlContent(vm.addToIndexText)
                .ariaLabel($translate.instant("document.areyousure"))
                .ok($translate.instant("global.button.add"))
                .cancel($translate.instant("global.button.cancel"))
                .openFrom('#left');
                $mdDialog.show(confirm).then(function () {
                    if( vm.tableSelectOptions.ids.length > 0 || response.selectedAll){
                        vm.isLoaderActive = true;
                        DocumentService.indexAddToDatabase({ids:response.ids}, vm.searchRequest, response.selectedAll).then(function () {
                            vm.isLoaderActive = false;
                            closeBottomSheet();
                            reloadDocuments();
                        }, function (error) {
                            PlagiatValidationService.getModalError(error.data);
                        });
                    }
                });
            });
        }

        function getDetails(item) {
            vm.showDetailsBlock = false;
            vm.pickedDocument = item.documentId;
            if (item.documentId !== DocumentService.documentDetailsObj.documentId) {
                DocumentService.documentDetailsObj = item;
                vm.isLoaderActive = true;

                if(!vm.isIndividual){
                    DocumentService.getDetailsForOperator(item.documentId).then(function (result) {
                        vm.isLoaderActive = false;
                        vm.showDetailsBlock = true;
                        vm.details = result.data;
                        vm.details.docuSubmitDate = moment(vm.details.docuSubmitDate).format('LL LT');
                        if (vm.details.docuReportDate){
                            vm.details.docuReportDate =  moment(vm.details.docuReportDate).format('LL LT');
                        }

                    });
                } else {
                    DocumentService.getDetails(item.documentId).then(function (result) {
                        vm.isLoaderActive = false;
                        vm.showDetailsBlock = true;
                        vm.details = result.data;
                        vm.details.docuSubmitDate = moment(vm.details.docuSubmitDate).format('LL LT');
                        if (vm.details.docuReportDate){
                            vm.details.docuReportDate =  moment(vm.details.docuReportDate).format('LL LT');
                        }
                    });
                }
            } else {
                DocumentService.documentDetailsObj = {};
            }
        }

        function getAvailableExtensions() {
            DocumentService.getDocumentAvailableExtension().then(function (result) {
                angular.forEach(result.data, function (value) {
                    vm.availableExtension += '.' + value + ',';
                });
            });
        }

        function uploadText(ev) {
            $mdDialog.show({
                templateUrl: 'app/account/documents/document-text/add-text.tmpl.html',
                targetEvent: ev,
                clickOutsideToClose: true
            }).then(shouldShowSynchronizationValidationWindow);
        }

        function getTheFiles($files, $invalidFiles) {
            preprocessFile($files, $invalidFiles).then(
                function (formData) {
                    vm.uploadFile(formData, vm.fileName);
                }, function (error) {
                    // console.error('getTheFiles error ' + error);
                });
        }
        
        function submitAssignmentPaidCorrection(documentId, $files, $invalidFiles) {
            vm.isLoaderActive = true;
            
            preprocessFile($files, $invalidFiles).then(function (formData) {
                vm.isLoading = true;
                
                DocumentService.operator.uploadFile(formData, vm.fileName)
                    .then(function (responseData) {
                        var submitForm = {};
                        submitForm.documentId = documentId;
                        submitForm.fileId = responseData.data.fileId;
                        
                        var size = responseData.data.size;

                        AssignmentsService.submitAssignmentPaidCorrection(submitForm).then(function (submitResponse) {
                            reloadDocuments();
                            var submitedDocumentId = submitResponse.documentId;
                            
                            var checkDocumentCallback = function(codeDialogParameters) {
                                var codeId = codeDialogParameters.codeId;

                                ModalService.showPleaseWaitDialog();
                                DocumentService.operator.checkDocument(submitedDocumentId, codeId)
                                    .then(function () {
                                        ModalService.showSuccessModal("global.messages.info.actionsuccess");
                                        reloadDocuments();
                                        ContractService.broadcast();
                                    }).catch(function (response) {
                                        PlagiatValidationService.getModalError(response.data);
                                    });
                            };

                            $mdDialog.show({
                                templateUrl: 'app/account/documents/document-code-check/document-check-code.dialog.html',
                                clickOutsideToClose: false,
                                locals: {
                                    items: {
                                        documentId: submitedDocumentId,
                                        checkDocumentCallback: checkDocumentCallback,
                                        isAssignment: true,
                                        size: size
                                    }
                                },
                                controllerAs: 'vm',
                                controller: DialogController
                            });

                        });
                        
                    }).catch(function (response) {
                        vm.isLoading = false;
                        PlagiatValidationService.getModalError(response.data);
                });
            });
        }

        function submitAssignmentCorrection(documentId, $files, $invalidFiles) {
            preprocessFile($files, $invalidFiles).then(function (formData) {
                vm.isLoading = true;
                DocumentService.operator.uploadFile(formData, vm.fileName)
                    .then(function (responseData) {
                        var submitForm = {};
                        submitForm.documentId = documentId;
                        submitForm.fileId = responseData.data.fileId;
                        
                        AssignmentsService.submitAssignmentCorrection(submitForm).then(function (submitResponse) {
                            vm.isLoading = false;
                            //vm.uploadIsActive = true;
                            vm.files = null;
                            vm.formdata = new FormData();

                            ModalService.showSuccessModal("assignment.upload.success");
                            reloadDocuments();
                        });

                    }).catch(function (response) {
                        vm.isLoading = false;
                        PlagiatValidationService.getModalError(response.data);
                    });
            })
        }

        function submitAssignment(documentId, $files, $invalidFiles) {
            preprocessFile($files, $invalidFiles).then(function (formData) {
                vm.isLoading = true;
                DocumentService.operator.uploadFile(formData, vm.fileName)
                  .then(function (responseData) {
                      AssignmentsService.getSubmitForm(documentId).then(function (submitFormResponse) {
                          vm.assignmentSubmitForm = submitFormResponse.data;
                          vm.uploadObj = responseData.data;
                          vm.assignmentSubmitForm.fileId = vm.uploadObj.fileId;
                          vm.isLoading = false;
                          vm.uploadIsActive = true;
                          vm.files = null;
                          vm.formdata = new FormData();
                      });

                }).catch(function (response) {
                    vm.isLoading = false;
                    PlagiatValidationService.getModalError(response.data);
                });
            })
        }

        function preprocessFile($files, $invalidFiles) {
            if ($files === null) {
                vm.files = null;
                return Promise.reject();
            } else {
                if ($invalidFiles.length === 0) {
                    var formData = new FormData();
                    vm.files = $files;
                    var i = 0;
                    angular.forEach(vm.files, function (value, key) {
                        vm.fileName = Utils.removeSpecialCharsForFileName(value.name);
                        formData.append(key, value);
                        i++;
                    });
                    if (i > 0) {
                        return Promise.resolve(formData);
                    }
                    return Promise.reject();
                }
            }
        }

        function uploadFile(obj, name) {
            vm.isLoading = true;
            vm.assignmentSubmitForm = null;
            vm.upfileFn = vm.isIndividual ? DocumentService.uploadFile(obj, name) : DocumentService.operator.uploadFile(obj, name);
            vm.upfileFn.then(function (responseData) {
                getUploadFormConfiguration(responseData.data).then(function(response){
                    vm.isLoading = false;
                    vm.uploadIsActive = true;
                    vm.uploadObj = response;
                    vm.files = null;
                    vm.formdata = new FormData();
                    shouldShowSynchronizationValidationWindow();
                });
            }).catch(function (response) {
                vm.isLoading = false;
                PlagiatValidationService.getModalError(response.data);
            });
        }

        function documentsSimpleSearch(documentsSearchRequest){
            vm.showDetailsBlock = false;
            vm.documentsSearchRequest = documentsSearchRequest;
            vm.searchRequest.pageNumber = (vm.queryRequest.page - 1);
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = sort();
            vm.searchRequest.showOnlyForUser = documentsSearchRequest.showOnlyForUser;
            vm.searchRequest.search = documentsSearchRequest.simpleSearch;

            if( DocumentService.searchUser){
                vm.searchRequest.showOnlyForUser = false;
                vm.searchRequest.search.showOnlyForUser = false;
                vm.searchRequest.search.searchUser = DocumentService.searchUser.id;
                ScrollService.scrollTo('table-anchor');
            }

            vm.isLoaderActive = true;

            DocumentService.simpleSearch(vm.searchRequest, onSuccess, onError).then(function (responseData) {
                var responseObj = responseData.content;
                angular.forEach(responseObj, function (value) {
                    value.docuSubmitDateTimestamp = value.docuSubmitDate;
                    value.docuSubmitDate = moment(value.docuSubmitDate).format('LL LT');
                });
                vm.documents = responseObj;
                vm.totalDocuments = responseData.totalElements;

                vm.isLoaderActive = false;
                DocumentService.searchUser = null;
            });
        }

        function getDocuments(documentsSearchRequest) {
            vm.showDetailsBlock = false;
            vm.documentsSearchRequest = documentsSearchRequest;
            vm.searchRequest.pageNumber = (vm.queryRequest.page - 1);
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = sort();
            vm.searchRequest.showOnlyForUser = documentsSearchRequest.showOnlyForUser;
            vm.searchRequest.search = documentsSearchRequest;

            if (DocumentService.searchUser) {
                vm.searchRequest.showOnlyForUser = false;
                vm.searchRequest.search.showOnlyForUser = false;
                vm.searchRequest.search.searchUser = DocumentService.searchUser.id;
                ScrollService.scrollTo('table-anchor');
            }

            vm.isLoaderActive = true;

            DocumentService.get(vm.searchRequest, onSuccess, onError).then(function (responseData) {
                var responseObj = responseData.content;
                angular.forEach(responseObj, function (value) {
                    value.docuSubmitDateTimestamp = value.docuSubmitDate;
                    value.docuSubmitDate = moment(value.docuSubmitDate).format('LL LT');
                });
                vm.documents = responseObj;
                vm.totalDocuments = responseData.totalElements;

                vm.isLoaderActive = false;
                DocumentService.searchUser = null;
                
                checkIfNeedToRefreshProcessingDocuments();
            });
        }

        function goTo() {
            getDocuments();
        }

        function startPaging(page) {
            resetSelection();
        	vm.isLoaderActive = true;
            vm.queryRequest.page = page;
            vm.documentsSearchRequest.simpleSearch.length > 0 ? documentsSimpleSearch(vm.documentsSearchRequest) : getDocuments(vm.documentsSearchRequest);
        }

        function changeLimit(n) {
            vm.queryRequest.limit = n;
            getDocuments();
        }

        function onSuccess(data, headers) {
            var hiddenUsersSize = 0;
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                    hiddenUsersSize++;
                }
            }
            vm.totalItems = headers('X-Total-Count') - hiddenUsersSize;
            vm.searchRequestCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.documents = data;
        }

        function onError(error) {
            $mdDialog.show(
                $mdDialog.confirm()
                .clickOutsideToClose(true)
                .title($translate.instant("global.messages.error.label"))
                .textContent($translate.instant("global.messages.error.label"))
                .ariaLabel('ok')
                .ok('OK')
                );
        }

        function sort() {
            var reverse = vm.queryRequest.order.slice(0, 1);
            var _length = vm.queryRequest.order.length;
            var predicate;
            if (reverse === '-') {
                predicate = vm.queryRequest.order.slice(1, _length);
                reverse = false;
            } else {
                predicate = vm.queryRequest.order;
                reverse = true;
            }
            var result = [predicate + ',' + (reverse ? 'asc' : 'desc')];
            //zakomentowane, w przypadku superadmina dodatkowe sortowanie bardzo spowalnia zapytanie o dokumenty
//            if (predicate !== 'id') {
//                result.push('id');
//            }
            return result;
        }

        function generateReportXls(){
        	prepareMassActions().then(function(response){
        		DocumentReportXlsService.generateReportXls(response, vm.selected, vm.searchRequest);
        	});
        }

        (function () {
            setRoles();
            getAvailableExtensions();
            afterUploadLandingPage();
            prepareDocuStatuses();
            prepareSearchRequest();
        })();

        function showEditForm() {
            $mdDialog.show({
                templateUrl: 'app/components/document/document-form.tmpl.html',
                clickOutsideToClose: false
            });
        }

        function findAllProtocolForDocuments() {
            ProtocolForDocumentService
                .getAllForCurrentClient()
                .then(function (responseData) {
                    vm.protocolForDocuments = responseData;
                });
        }

        //visibilities
        function isCoordinatorVisible() {
            return !vm.isIndividual && !vm.clientTypePublishingHouse && !vm.isSuperAdmin;
        }
        function isUploadTextVisible() {
            return !vm.isSuperAdmin && !vm.isReseller && !vm.isMinistry && Principal.hasNotAuthority('PERM_USER_DO_NOT_USE_COPY_PASTE') && !(vm.isStudent && vm.clientSettings.noDocumentUploadForStudents);
        }

        function isUploadFileVisible() {
            return !vm.isSuperAdmin && !vm.isReseller && !vm.isMinistry && !(vm.isStudent && vm.clientSettings.noDocumentUploadForStudents);
        }

        function isStudent() {
            return vm.isStudent;
        }

        function populateAiDetection() {
            if (vm.uploadObj.aiDetectionInfo) {
                const info = vm.uploadObj.aiDetectionInfo;
                if (info.isPresent) {
                    if ('defaultDisabled' === info.defaultValue) {
                        vm.uploadObj.aiDetection = false;
                    }
                    if (('defaultEnabled' === info.defaultValue) || ('alwaysEnabled' === info.defaultValue)) {
                        vm.uploadObj.aiDetection = true;
                    }
                }
            }
        }

        function populateAuthor() {
            if (!isStudent())
                return;

            vm.uploadObj.authors = {};
            vm.uploadObj.authors.idx0 = {};
            if (vm.authorsSimplifiedFields) {
                vm.uploadObj.authors.idx0.firstName = vm.uploadObj.authorFirstName + ' ' + vm.uploadObj.authorLastName;
            } else {
                vm.uploadObj.authors.idx0.firstName = vm.uploadObj.authorFirstName;
                vm.uploadObj.authors.idx0.lastName = vm.uploadObj.authorLastName;
            }
        }

        function convertToDate(date){
            return new Date(date);
        }

        function assignmentAfterDueDate(document){
            return document.assignmentEndDate && (document.docuSubmitDateTimestamp > document.assignmentEndDate);
        }

        function userDatabaseAddingEnabled() {
            return AccountService.getClientSettings().addingToDatabase === 'automatically'
                || AccountService.getClientSettings().addingToDatabase === 'delayed'
                || AccountService.getClientSettings().addingToDatabase === 'manually';
        }

        function getDocumentStatusTooltipKey(document) {
            if (document.docuStatus === DOCUMENT_STATUS.MARKED_FOR_DATABASE.id) {
                if (document.orppdStatus === ORPPD_STATUS.SENT.id) {
                    return 'document.table.statuses.picked.orppd-sent';
                }
                if (!vm.report2020Disabled) {
                    return 'document.table.statuses.picked.report-accepted';
                }
                return 'document.table.statuses.picked';
            }
            if (document.docuStatus === DOCUMENT_STATUS.ADDED_TO_DATABASE.id) {
                if (document.orppdStatus === ORPPD_STATUS.SENT.id) {
                    return 'document.table.statuses.added.orppd-sent';
                }
                if (!vm.report2020Disabled) {
                    return 'document.table.statuses.added.report-accepted';
                }
                return 'document.table.statuses.added';
            }
            return '';
        }

        function checkIfNeedToRefreshProcessingDocuments() {
            if (vm.isSuperAdmin || vm.isReseller) {
                return; // nie robimy tego dla superadmina i resellera
            }
            vm.documentsToRefresh = [];
            cancelDocumentsToRefreshInterval();
            angular.forEach(vm.documents, function (doc) {
                if (doc.docuStatus == 0) {
                    vm.documentsToRefresh.push(doc.documentId);
                }
            });
            if (vm.documentsToRefresh.length > 0) {
                vm.documentsToRefreshPromise = $interval(doRefreshProcessingDocuments, 40000);
            }
        }
        
        function doRefreshProcessingDocuments() {
            DocumentService.checkDocumentStatuses(vm.documentsToRefresh).then(function (responseData) {
                vm.newStatuses = responseData.data;
                if (!angular.equals(vm.newStatuses, {})) {
                    cancelDocumentsToRefreshInterval();
                    reloadDocuments();
                }
            }, function () {
                cancelDocumentsToRefreshInterval();
            });
        }
        
        function cancelDocumentsToRefreshInterval() {
            $interval.cancel(vm.documentsToRefreshPromise);
        }
        
        function DialogController($scope, $mdDialog, items) {
            $scope.items = items;
        }
        
        function selfEnrollment() {
            $mdDialog.show({
                clickOutsideToClose: true,
                templateUrl: 'app/account/documents/self-enrollment/self-enrollment.template.html',
                controller: SelfEnrollmentController,
                multiple:true,
                controllerAs: 'vm',
                locals: {
                    items: {
                        onJoin: vm.reloadDocuments
                    }
                }
            });
            
        }


    }

})();

(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['DetectInstanceService', '$translate', '$log', '$timeout', 'Auth', 'UserRegisterRequest'];

    function RegisterController (DetectInstanceService, $translate, $log, $timeout, Auth, UserRegisterRequest) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.register = register;
        vm.registerObj = new UserRegisterRequest();
        vm.success = null;
        vm.pending = false;
        vm.instance = DetectInstanceService.instance();
        vm.allowFbLogin = DetectInstanceService.getFbLoginForm();
        vm.allowRegister = DetectInstanceService.getAllowRegister();
        vm.passwordPattern = Auth.getPasswordPattern();
        vm.toggleShowPassword = toggleShowPassword;
        vm.showPassword = false;
        vm.regulationsAcceptanceInfo = regulationsAcceptanceInfo;

        $timeout(function (){angular.element('#login').focus();});

        function resetDisplays(){
            vm.success = null;
            vm.error = false;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;
            vm.isReserved = null;
            vm.doNotMatch = null;
        }

        function register(form) {
            for (var i = 0; i < form.$$controls.length; i++) {
                form.$$controls[i].$touched = true;
            }
            if (form.$invalid) {
                return;
            }

            vm.pending = true;
            resetDisplays();
            if (vm.registerObj.password !== vm.registerObj.confirmedPassword) {
                vm.doNotMatch = true;
                vm.error = true;
                vm.pending = false;
            } else {
                vm.registerObj.langKey = $translate.use();
                Auth.createAccount(vm.registerObj).then(function () {
                    vm.success = true;
                    vm.error = false;
                    vm.pending = false;
                }).catch(function (response) {
                    vm.error = true;
                    vm.success = false;
                    vm.pending = false;
                    if (response.data.status === "fail") {
                        if( response.data.code === '004' ) vm.otherError = true;
                        if( response.data.code === '005' ) vm.isReserved = true;
                        if( response.data.code === '006' ) vm.errorEmailExists = true;
                    } else {
                        vm.otherError = true;
                    }
                });
            }
        }
    
        function toggleShowPassword() {
            vm.showPassword = !vm.showPassword;
        }

        function regulationsAcceptanceInfo() {
            var regulationsKey = 'info.instructions.instruction3.url_' + vm.instance;
            var regulationsTranslation = $translate.instant(regulationsKey);
            var companyNameKey = vm.instance === 'ro' ? 'global.company.name.ro' : 'global.company.name';
            var companyNameTranslation = $translate.instant(companyNameKey);
            
            var result = $translate.instant('global.social.regulations-acceptance-info');
            result = result.replace('{regulationsUrl}', regulationsTranslation);
            result = result.replace('{companyName}', companyNameTranslation);
            result =  '<span>' + result + '</span>';
            return result;
        }
    
    }
})();

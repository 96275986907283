(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('LandingController', LandingController);

    LandingController.$inject = ['Principal', '$scope', '$stateParams', '$state', '$rootScope', '$translate', 'InstanceService', 'DetectInstanceService', 'JhiLanguageService', 
        '$log', '$window', 'AUTO_TRANSLATIONS_LANGS',
        'LandingService', 'Auth', '$mdDialog', '$timeout', 'PlagiatValidationService', '$sessionStorage', 'ExtraOptionsService', 'Utils'];

    function LandingController(Principal, $scope, $stateParams, $state, $rootScope, $translate, InstanceService, DetectInstanceService, JhiLanguageService, 
                               $log, $window, AUTO_TRANSLATIONS_LANGS,
                               LandingService, Auth, $mdDialog, $timeout, PlagiatValidationService, $sessionStorage, ExtraOptionsService, Utils) {
        var vm = this;
    
        vm.instance = DetectInstanceService.instance();
        vm.instanceSettings = InstanceService.instance();
        vm.landingLink = vm.instanceSettings.landingLink
        vm.instanceLang = DetectInstanceService.getLandingLang();
        vm.threeStateRadios = DetectInstanceService.instance() === 'int';
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.landingPage = true;
        vm.key = $stateParams.key;
        vm.autoTranslationLangs = Utils.sortAutoTranslationsLanguages(AUTO_TRANSLATIONS_LANGS, $translate);

        vm.user = {};
        vm.register = register;
        vm.uploadButtonDisabled = uploadButtonDisabled;
        vm.pending = false;
        vm.validated = false;
        vm.fileName = false;
        vm.singleTokenPrice = null;
        vm.currentLang = null;
        var formData = new FormData();
        vm.clearFiles = clearFiles;
        vm.getTheFiles = getTheFiles;
        vm.isLoading = false;
        vm.isCalculatingPrice = false;
        vm.setLang = setLang;
        vm.toggleShowPassword = toggleShowPassword;
        vm.checkboxChange = checkboxChange;
        vm.isAiDetectionEnabled = isAiDetectionEnabled;
        vm.isOptionDisabled = isOptionDisabled;
        vm.getPriceInCurrency = getPriceInCurrency;
        vm.getDefaultCurrency = getDefaultCurrency;
        vm.currencyChosen = currencyChosen;
        vm.chooseCurrency = chooseCurrency;
        vm.fbButtonDisabled = fbButtonDisabled;
        vm.translationsOK = translationsOK;
        vm.showPassword = false;
        vm.freeChecksAvailable = true;
        vm.documentSize = null;
        vm.optionPrices = {};
        vm.availableExtensions = null;
        vm.translation = {};
        vm.translation.price = 6;
        moment.locale(vm.instanceLang);

        setLang(vm.instanceLang);
        vm.currency = vm.getDefaultCurrency();

        this.$onInit = function () {
            angular.element('body').css({
                'background-image': 'url(content/images/landing.jpg)',
                'background-repeat': 'no-repeat',
                'background-size': 'cover'
            });
            LandingService.getTokenPriceList({}).then(function (responseData) {
                vm.charactersPerToken = responseData.data.charactersPerToken;
                vm.documentExtraOptions = responseData.data.extraOptions;
            });
            LandingService.getSingleTokenPrince(vm.currency, getPaymentType()).then(function (responseData) {
                vm.singleTokenPrice = responseData.data;
            });
            LandingService.getAvailableFileExtensions().then(function (responseData) {
                vm.availableExtensions = responseData.data;
            });
        };

        function afterUploadLandingPage() {
            if (vm.key === 'error') {
                var confirm = $mdDialog.confirm()
                .title('')
                .clickOutsideToClose(true)
                .htmlContent($translate.instant("global.messages.error.error"))
                .ariaLabel('')
                .ok('Ok')
                .openFrom('#left');
                $mdDialog.show(confirm).then(function (result) {
                });
            }
        }

        if(vm.key === 'facebook'){
            if($sessionStorage.landingPageFileId) {
                vm.isLoading = true;
                vm.pending = true;
                toogleElements('none');
                showDialog();
                uploadForm(formData);
            }
        }

        function redirect(){
            vm.isAuthenticated = Principal.isAuthenticated;
            if( vm.isAuthenticated ){
                $state.go('documents');
            }
        }

        function clearFiles() {
            vm.files = null;
            vm.fileName = null;
            vm.documentSize = null;
            vm.optionPrices = {};
            vm.translation = null;
        }

        function getTheFiles($files, $invalidFiles) {
            formData = new FormData();
            if ($files.length === 0) {
                vm.files = null;
                return;
            }
            if ($invalidFiles.length === 0) {
                vm.files = $files;
                angular.forEach(vm.files, function (value, key) {
                    vm.invalidFiles = {
                        maxError: false
                    };
                    vm.fileName = value.name;
                    formData.append(key, value);
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(value);
                });
                uploadFile();
            } else {
                vm.invalidFiles = {};
                angular.forEach($invalidFiles, function (value, key) {
                    vm.fileName = false;
                    vm.invalidFiles.maxError = value.$error;
                });
            }
        }

        function uploadFile() {
            vm.isCalculatingPrice = true;
            LandingService.uploadFile(formData, vm.fileName).then(function (response) {
                vm.isCalculatingPrice = false;
                vm.documentSize = response.data.documentSize;
                $sessionStorage.landingPageFileId = response.data.fileId;
                recalculatePrices();
            }, function (error) {
                catchError();
            });
        }

        function checkboxChange() {
            recalculatePrices();
        }

        function isAiDetectionEnabled() {
            return ExtraOptionsService.isAiDetectionEnabled(vm);
        }

        function isOptionDisabled(optionId) {
            return ExtraOptionsService.isOptionDisabled(vm.size, optionId);
        }

        function recalculatePrices() {
            ExtraOptionsService.recalculatePrices(vm.documentSize, vm);
            $sessionStorage.landingPageDocumentExtraOptions = ExtraOptionsService.getCurrentDocumentOptions(vm);
            $sessionStorage.translation = vm.translation;
        }

        function setLang(lang){
            $translate.use( lang );
            //tmhDynamicLocale.set(languageKey);
            vm.user.langKey = lang;
        }

        function catchError(){
            toogleElements('block');
            $mdDialog.hide();
            Auth.logout();
            switch (vm.instanceLang) {
                case 'ro':
                    $state.go('landing-page-ro', {key: 'error'});
                    break;
                case 'en':
                    $state.go('landing-page-int', {key: 'error'});
                    break;
                default:
                    $state.go('landing-page', {key: 'error'});
            }
            vm.isLoading = false;
            vm.pending = false;
            vm.fileName = false;
            clearSessionStorage();
        }

        function toogleElements(style){
            $timeout(function () {
                if (document.getElementById("navbar")) {
                    document.getElementById("navbar").style.display = style;
                }
                if (document.getElementById("sidenav")) {
                    document.getElementById("sidenav").style.display = style;
                }
            }, 1);
        }

        function showDialog(){
            $mdDialog.show({
                template:
                '<md-dialog aria-label="List dialog">' +
                '  <md-toolbar>' +
                '  <div class="md-toolbar-tools">' +
                '  <h2 data-translate="landing.loader.title"></h2>' +
                '  </div>' +
                '  </md-toolbar>' +
                '  <form name="form" ng-cloak>' +
                '  <md-dialog-content>' +
                '   <div class="md-dialog-content" layout-margin >' +
                '<div class="loader" layout="row" layout-sm="column" layout-align="space-around">' +
                '<md-progress-circular md-mode="indeterminate"></md-progress-circular>' +
                '</div>' +
                '    <md-input-container class="md-block">' +
                '       <span data-translate="landing.loader.content"></span>' +
                '   </div>' +
                '  </md-dialog-content>' +
                '  </form>' +
                '</md-dialog>',
                clickOutsideToClose: false,
                escapeToClose: false,
                controllerAs: '_dialog',
                controller: function DialogController($scope, $mdDialog) {
                    var _dialog = this;
                    _dialog.closeDialog = closeDialog;
                    _dialog.obj = {};
                    function closeDialog() {
                        $mdDialog.hide();
                        $timeout(function () {
                            Auth.logout();
                            switch (vm.instanceLang) {
                                case 'ro':
                                    $state.go('landing-page-ro', {key: 'error'});
                                    break;
                                case 'en':
                                    $state.go('landing-page-int', {key: 'error'});
                                    break;
                                default:
                                    $state.go('landing-page', {key: 'error'});
                            }
                        }, 1);
                    }
                }
            }).finally(
            function onModalClose() {
              Auth.logout();
          });
        }

        function trackFbPixel() {
            if ($window.fbq) {
                var price = getPriceInCurrency();
                if (typeof price !== 'undefined') {
                    $window.fbq('track', 'InitiateCheckout', {currency: vm.currency, price: price});
                    //console.log('InitiateCheckout tracked! value=' + price + ', currency=' + vm.currency);
                } else {
                    $window.fbq('track', 'InitiateCheckout');
                    //console.log('InitiateCheckout tracked! no value=');
                }
            }
        }

        function getPriceInCurrency() {
            if (vm.totalPrice && vm.singleTokenPrice) {
                return (vm.totalPrice * vm.singleTokenPrice).toFixed(2);
            } else {
                return undefined;
            }
        }
  
        function currencyChosen(buttonCurrency) {
            return vm.currency && (vm.currency === buttonCurrency);
        }
  
        function chooseCurrency(newCurrency) {
            vm.currency = newCurrency;
            refreshSingleTokenPrice();
        }
        
        function getDefaultCurrency() {
            var cur = vm.instanceSettings.currencies.landingCurrencies.find(function (value) {
                if (vm.instanceLang === value.lang) {
                    return value.defaultCurrency;
                }
            });
            if (cur) {
                vm.availableCurrencies = cur.currencyList;
                return cur.defaultCurrency;
            } 
            return 'EUR';
        }
        
        function register(form1, form2) {
            vm.validated = true;
            if (!Utils.validateForm(form1) || !Utils.validateForm(form2)) {
                console.log('form not valid');
                return;
            }
            trackFbPixel();

            vm.isLoading = true;
            LandingService.sendObj(vm.user).then(function (response) {
                vm.pending = true;
                showDialog();
                Auth.login({
                    username: response.data.email,
                    password: response.data.password,
                    rememberMe: false
                }).then(function () {
                    toogleElements('none');
                    uploadForm();
                }, function () {
                    catchError();
                });
            }, function (response) {
                if (response.data.code === '120') { //uzytkownik zarejestrowany na innym kliencie indywidualnym, np. 1 -> 9999
                    $.redirect(response.data.data, null, 'GET');
                } else {
                    vm.isLoading = false;
                    PlagiatValidationService.getModalError(response.data);
                }
            });
        }

        function refreshSingleTokenPrice() {
            vm.isLoading = true;
            LandingService.getSingleTokenPrince(vm.currency, getPaymentType()).then(function (responseData) {
                vm.singleTokenPrice = responseData.data;
                vm.isLoading = false;
            });
        }
        
        function getPaymentType() {
            return vm.instanceSettings.currencies.settings.find(function (value) {
                if (vm.currency === value.name) {
                    return value.methodsOfPayment;
                }
            }).methodsOfPayment[0];
        }
        
        function uploadForm() {
            if (Auth.getPreviousState()) {
                Auth.resetPreviousState();
            }
            var extraOptions = (!!$sessionStorage.landingPageDocumentExtraOptions && $sessionStorage.landingPageDocumentExtraOptions > 0) ?
              $sessionStorage.landingPageDocumentExtraOptions : 0;
            var translationFromStorage = ($sessionStorage.translation) ? $sessionStorage.translation : null; 
            var paymentType = getPaymentType();
            LandingService.addDocument($sessionStorage.landingPageFileId, extraOptions, vm.currency, paymentType, translationFromStorage).then(function (response) {
                if (response.data.formAction !== "") {
                    clearSessionStorage();
                    vm.isLoading = false;
                    vm.fileName = false;
                    $rootScope.$broadcast('authenticationSuccess');
                    vm.paymentParams = response.data.paymentRedirectionParams;
                    var $redirectFormContainer = angular.element('#paymentForm');
                    var $redirectForm = $redirectFormContainer.find('form');
                    $redirectForm.attr('method', response.data.formMethod);
                    $redirectForm.attr('action', response.data.formAction);
                    $timeout(function () {
                        $redirectForm.submit();
                    }, 20);
                } else {
                    $window.location = "#/documents";
                    $window.location.reload();
                }
            }, function () {
                catchError();
            });
        }

        function clearSessionStorage() {
            $sessionStorage.landingPageFileId = undefined;
            $sessionStorage.landingPageDocumentExtraOptions = undefined;
            $sessionStorage.translation = undefined;
        }
    
        function toggleShowPassword() {
            vm.showPassword = !vm.showPassword;
        }
        
        function uploadButtonDisabled() {
            return vm.pending
                || !vm.fileName 
                || vm.isCalculatingPrice 
                || (vm.instance == 'ro' && vm.user.email !== vm.user.email2) 
                || !vm.translationsOK();
        }
        
        function fbButtonDisabled() {
            return !vm.user.acceptDataProcesing || !vm.user.acceptRegulations || vm.pending || !vm.fileName || vm.isCalculatingPrice || !vm.translationsOK();
        }
        
        function translationsOK() {
            if (vm.translation && vm.translation.chosen) {
                if (!vm.translation.autoTranslationsToLang) {
                    return false;
                }
            }
            return true;
        }
    
        afterUploadLandingPage();
    }

})();

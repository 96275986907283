(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .constant('TRANSLATION_TYPES', [
        {'id': 0, 'key': 'translations.search.type.2017' },
        {'id': 1, 'key': 'translations.search.type.2010' },
        {'id': 2, 'key': 'translations.search.type.new'  },
        {'id': 3, 'key': 'translations.search.type.asap3' }  ]);
})();

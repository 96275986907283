(function() {
	'use strict';

    ExtraOptionsController.$inject = ['$scope', '$mdDialog', '$state', '$translate', 'AUTO_TRANSLATIONS_LANGS', 'Utils',
        'documentSize', 'documentId', 'onSuccess',
        'TokenService', 'DetectInstanceService', 'ExtraOptionsService'];

    function ExtraOptionsController($scope, $mdDialog, $state, $translate, AUTO_TRANSLATIONS_LANGS, Utils,
                                    documentSize, documentId, onSuccess,
                                    TokenService, DetectInstanceService, ExtraOptionsService) {
		var vm = this;
		vm.pending = true;
		vm.initializing = true;
		vm.checkDisabled = false;
		vm.instance = DetectInstanceService.instance();
        vm.threeStateRadios = DetectInstanceService.instance() === 'int';
        vm.autoTranslationLangs = Utils.sortAutoTranslationsLanguages(AUTO_TRANSLATIONS_LANGS, $translate);

		vm.tokenInfo = {};
		vm.freeChecksAvailable = true;
		vm.availableTokens = 0;
		vm.basePrice = 0;
		vm.size = 0;
		vm.totalPrice = 0;
		vm.closeDialog = closeDialog;
		vm.buyTokens = buyTokens;
		vm.checkDocument = checkDocument;
		vm.checkboxChange = checkboxChange;
		vm.translationsValidated = translationsValidated;
		vm.isAiDetectionEnabled = isAiDetectionEnabled;
		vm.isOptionDisabled = isOptionDisabled;

		init();

		function init() {
		    vm.pending = true;
		    vm.initializing = true;
            TokenService.getTokenPriceList({
                documentId: documentId,
                documentSize: documentSize
            }).then(function (responseData) {
                vm.charactersPerToken = responseData.data.charactersPerToken;
                vm.documentExtraOptions = responseData.data.extraOptions;
                vm.freeChecksAvailable = responseData.data.freeChecksAvailable;
                vm.translation = {};
                vm.translation.chosen = responseData.data.autoTranslations;
                vm.translation.autoTranslationsToLang = responseData.data.autoTranslationsToLang;
                // console.log('tr=' + JSON.stringify(vm.translation));
                recalculatePrices();
                TokenService.getInfo().then(function (responseData) {
                    vm.tokenInfo = responseData.data;
                    vm.availableTokens = responseData.data.availableTokens;
                    vm.pending = false;
                    vm.initializing = false;
                });
            });
        }

        function buyTokens() {
            TokenService.buyTokens = true;
            TokenService.tokensNedded = vm.totalPrice - vm.availableTokens;
            $mdDialog.hide();
            $state.go('payments', {documentId: documentId});
        }

        function checkDocument() {
            $mdDialog.hide();
            onSuccess(getCurrentDocumentOptions(), vm.totalPrice, vm.availableTokens);
        }

        function checkboxChange() {
            recalculatePrices();
        }

        function getCurrentDocumentOptions() {
          return ExtraOptionsService.getCurrentDocumentOptions(vm);
        }

        function isAiDetectionEnabled() {
		    return ExtraOptionsService.isAiDetectionEnabled(vm);
        }

        function isOptionDisabled(optionId) {
		    return ExtraOptionsService.isOptionDisabled(vm.size, optionId);
        }

		function recalculatePrices() {
            return ExtraOptionsService.recalculatePrices(documentSize, vm);
		}

		function closeDialog() {
			$mdDialog.hide();
		}
		
		function translationsValidated() {
            if (vm.translation.chosen) {
                if (!vm.translation.autoTranslationsToLang) {
                    return false;
                }
            }
		    return true;
        }

	}

	angular
        .module('plagiat2017App')
        .controller('ExtraOptionsController', ExtraOptionsController);

})();
